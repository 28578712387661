import React, {Fragment} from "react"
import { wrapUserConsumer, locateEntityInData } from "../components/user-context";
import { doReplacements } from "../utils/replacements";
import { graphql } from "gatsby";
import ImagineSpinner from "../components/spinner"

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  Form,
  Container,
  Row,
  Col,
} from "reactstrap";


function Policy({text}){
  return (
    <div dangerouslySetInnerHTML={{__html: text}}/>
  );
}


function PolicyPage({user, data, pageContext}){
  if(typeof user === `undefined`){
    // This can be undefined in the build sometimes, so this is all I can think of TODO: show a shell?
    return (
      <Fragment></Fragment>
    );
  }
  const userData = user.userData;
  let body = data[pageContext.bookName].body.value;
  if(user.initialLoadingComplete){
    const chapters = data.allNodeChapter.edges.map(edge => edge.node);
    body = doReplacements(chapters, userData, body);
  }
  return (
    <div>
      { user.initialLoadingComplete ?
        (
          <Fragment>
            <Container className="mt--6" fluid>
              <Row className="justify-content-center">
                <Col className="card-wrapper" lg="8">
                  <Card>
                    <CardBody>
                      <CardText className="mt-4 pb-4 border-bottom">
                        <div className="mt-4 pb-4 border-bottom card-text">
                          <Policy text={body}/>
                        </div>
                      </CardText>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Fragment>
        ) : (
          <ImagineSpinner />
        )
      }
    </div>
  );

}

export const query = graphql`
  query PolicyBookQuery($queriedLanguage: String, $drupalId: String){
    nodeDefaultParentHandbook(drupal_id: {eq: $drupalId}, queriedLanguage: {eq: $queriedLanguage}) {
      body {
        value
      }
    }
    nodeDefaultProgramPolicy(drupal_id: {eq: $drupalId}, queriedLanguage: {eq: $queriedLanguage}) {
      body {
        value
      }
    }
    nodeDefaultStaffPolicy(drupal_id: {eq: $drupalId}, queriedLanguage: {eq: $queriedLanguage}) {
      body {
        value
      }
    }
    allNodeChapter(filter: {queriedLanguage: {eq: $queriedLanguage}}) {
      edges {
        node {
          relationships {
            field_sections {
              relationships {
                field_questions {
                  ... on node__checkboxes_question {
                    drupal_id
                    internal {
                      type
                    }
                    relationships {
                      field_requirements {
                        drupal_id
                      }
                      field_policy_output {
                        field_token
                        field_replacement_text {
                          value
                        }
                      }
                      field_requirements {
                        drupal_id
                      }
                      field_options {
                        drupal_id
                        relationships {
                          field_policy_output {
                            field_replacement_text {
                              value
                            }
                            field_token
                          }
                        }
                      }
                    }
                  }
                  ... on node__essay_question {
                    drupal_id
                    internal {
                      type
                    }
                    relationships {
                      field_requirements {
                        drupal_id
                      }
                      field_policy_output {
                        field_token
                      }
                      field_requirements {
                        drupal_id
                      }
                    }
                  }
                  ... on node__text_question {
                    drupal_id
                    internal {
                      type
                    }
                    relationships {
                      field_requirements {
                        drupal_id
                      }
                      field_policy_output {
                        field_token
                      }
                      field_requirements {
                        drupal_id
                      }
                    }
                  }
                  ... on node__schedule_question {
                    drupal_id
                    internal {
                      type
                    }
                    relationships {
                      field_policy_output {
                        field_token
                      }
                    }
                  }
                  ... on node__image_question {
                    drupal_id
                    internal {
                      type
                    }
                    relationships {
                      field_requirements {
                        drupal_id
                      }
                      field_policy_output {
                        field_token
                      }
                    }
                  }
                  ... on node__radios_question {
                    drupal_id
                    internal {
                      type
                    }
                    relationships {
                      field_requirements {
                        drupal_id
                      }
                      field_policy_output {
                        field_token
                        field_replacement_text {
                          value
                        }
                      }
                      field_requirements {
                        drupal_id
                      }
                      field_options {
                        drupal_id
                        relationships {
                          field_policy_output {
                            field_replacement_text {
                              value
                            }
                            field_token
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            field_chapter_review_section {
              path {
                alias
              } 
              title
              drupal_id
              relationships {
                field_questions {
                  ... on node__checkboxes_question {
                    drupal_id
                    internal {
                      type
                    }
                    relationships {
                      field_requirements {
                        drupal_id
                      }
                      field_policy_output {
                        field_token
                        field_replacement_text {
                          value
                        }
                      }
                      field_options {
                        drupal_id
                        relationships {
                          field_policy_output {
                            field_replacement_text {
                              value
                            }
                            field_token
                          }
                        }
                      }
                    }
                  }
                  ... on node__essay_question {
                    drupal_id
                    internal {
                      type
                    }
                    relationships {
                      field_requirements {
                        drupal_id
                      }
                      field_policy_output {
                        field_token
                        field_replacement_text {
                          value
                        }
                      }
                    }
                  }
                  ... on node__radios_question {
                    drupal_id
                    internal {
                      type
                    }
                    relationships {
                      field_requirements {
                        drupal_id
                      }
                      field_policy_output {
                        field_token
                        field_replacement_text {
                          value
                        }
                      }
                      field_options {
                        drupal_id
                        relationships {
                          field_policy_output {
                            field_replacement_text {
                              value
                            }
                            field_token
                          }
                        }
                      }
                    }
                  }
                  ... on node__image_question {
                    drupal_id
                    internal {
                      type
                    }
                    relationships {
                      field_requirements {
                        drupal_id
                      }
                      field_policy_output {
                        field_token
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
`;
export default wrapUserConsumer(PolicyPage);
